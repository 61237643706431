import React from 'react'

import Layout from '../../components/layout'
import { SingleSection } from '../../components/sections'
import { Col } from 'react-bootstrap'

const Revisione = () => (
<Layout frame='appNav'>
    <SingleSection id='revisioni'>
        <Col md={12} className='text-center heading-section'>
            <h3>Standard revisione</h3>
            <p>
                Secondo lo SR890, l'ufficio di revisione verifica l'esistenza di un SCI
            </p>
        </Col>
        <Col md={{ span: 6, offset:3 }}>
            <ul className='group-heading'>
                <li>disponibile e verificabile (documentato)</li>
                <li>adeguato ai rischi e all'ampiezza delle attività aziendali</li>
                <li>conosciuto dei collaboratori</li>
                <li>effettivamente utilizzato</li>
                <li>che attesta la sensibilità ai controlli nelll'organizzazione</li>
            </ul>
        </Col>
    </SingleSection>
</Layout>
)

export default Revisione
